var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"text-black q-pa-sm no-shadow list-cards"},[_c('q-item',[_c('q-item-section',{attrs:{"top":"","avatar":""}},[_c('EntityThumbnail',{attrs:{"entity_id":_vm.data.entity_id,"entity_type_id":_vm.data.entity_type_id,"width":'50px'}})],1),_c('q-item-section',[_c('q-item-label',{staticClass:"text-weight-bold"},[_vm._v(_vm._s(_vm.data.name))]),(_vm.data.date_end !== null)?_c('q-item-label',{staticClass:"text-primary",attrs:{"caption":""}},[_vm._v(_vm._s(_vm._f("filterEventDate")(_vm.data.date_start,_vm.data.date_end))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.time_start)+" to "+_vm._s(_vm.data.time_end))]):_c('q-item-label',{staticClass:"text-primary",attrs:{"caption":""}},[_vm._v(_vm._s(_vm._f("filterEventDate")(_vm.data.date_start,_vm.data.date_start))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.time_start)+" to "+_vm._s(_vm.data.time_end))]),_c('q-item-label',{staticClass:"text-green",attrs:{"caption":""}},[_vm._v("Green Fee costs ₱ "+_vm._s(_vm.data.green_fee))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v(_vm._s(_vm.data.course_name))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v(_vm._s(_vm.data.privacy)+" Event")])],1),_c('q-item-section',{attrs:{"side":"","middle":""}},[_c('q-btn',{attrs:{"round":"","color":"primary","icon":"keyboard_arrow_right"},on:{"click":function($event){return _vm.$router.push(
                        {
                            name: 'Event',
                            params: {
                                id: _vm.data.id
                            }
                        }
                    )}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }