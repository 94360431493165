//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import EntityThumbnail from '@/components/EntityThumbnail.vue'
export default {
    props: ['data'],
    components: { EntityThumbnail },
      filters: {
        filterEventDate: function (date,date_end) {
           return date_end == date ? moment(new Date(date)).format('MMMM DD, YYYY') :   moment(new Date(date)).format('MMMM DD, YYYY') + ' - ' + moment(new Date(date_end)).format('MMMM DD, YYYY')
        },
    },
}
